#main-header{
  justify-content: flex-start;
}
#main-header .breadcrumb-separator {
  color: #6E84A38A !important;
  margin: auto 4px;
  user-select: none;
  font-size: 0.625rem;
}

.breadcrumb-item {
  color: #D3D2D9;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
  text-transform: uppercase;
}

.breadcrumb-last-item {
  color: #767380;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
  text-transform: uppercase;
}

.breadcrumb-item a:hover{
  color: #767380 !important;
}

#main-header ol {
  margin-bottom: 0px;
}

.breadcrumb-collapser {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  /* #main-header .icon-home{
    display: none;
  } */
  #main-header ol {
    margin-left: 0;
  }

  .breadcrumb-mobile{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 125px;
  }

  

}