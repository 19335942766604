@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("/src/index.css");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@700&display=swap');

/* SETUP */
a,
a:hover,
a:focus {
  transition: all 0.2s !important;
}

.content {
  width: 100%;
  padding: 0px 80px;
  padding-top: 0px;
  transition: all 0.3s;
  padding-bottom: 30px;
}

.page-content {
  margin-bottom: 50px;
}

.display-nocontent {
  text-align: center;
  width: 100%;
  border-radius: 8px;
  background: white;
  padding: 25px;
}

.table-condensed {
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f9fbfd !important;
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  text-align: left;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #95aac9;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.wrapper-light {
  display: flex;
  width: 100%;
  align-items: stretch;
  background: transparent;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.container-index {
  padding-top: 0px;
  width: 100%;
  margin-left: 0;
  min-height: 100vh;
  transition: all 0.3s;
  background: #f5f8f8;
}

.container-index.logged {
  padding-top: 0px;
  width: 100%;
  margin-left: var(--sidebar-width);
  min-height: 100vh;
  transition: all 0.3s;
  background: #f5f8f8;
}

@media screen and (min-width: 150px) and (max-width: 991px) {
  .container-index.logged {
    margin-left: 0;
    transition: all 0.3s;
  }
}

.swal-wide {
  width: 100vw !important;
}

.swal2-shown {
  overflow: unset !important;
  padding-right: 0px !important;
}

ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.btn svg {
  margin-right: 0.5rem;
}

.btn-info {
  color: #fff;
}

.btn-info:hover {
  color: #fff;
}

.btn-info:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #edf2f9;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.card-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #edf2f9;
}

.card-header-title {
  margin-bottom: 0;
}

h4.card-header-title {
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
}

.card-content {
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
  background: #fff;
  color: #4f6c7c;
}

/* FORMS */
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input::placeholder {
  color: #12263f !important;
}

select option {
  color: #12263f !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b1c5d8 !important;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1c5d8 !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1c5d8 !important;
}

.form-control::-webkit-input-placeholder {
  color: #b1c5d8 !important;
}

.card-search .input-custom::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #95aac9 !important;
  opacity: 1;
  /* Firefox */
}

.card-search .input-custom:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #95aac9 !important;
}

.card-search .input-custom::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #95aac9 !important;
}

.card-search .input-custom::-webkit-input-placeholder {
  color: #95aac9 !important;
}

.btn-height {
  height: 48px;
}

h1 {
  font-family: "Rubik", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #4f6c7c;
  text-align: left;
}

.subtitle {
  color: #4f6c7c;
  margin-top: 5px;
  margin-left: 5px;
  font-family: "Rubik", sans-serif;
}

.form-control {
  border: 1px solid #ced4da;
}

.form-control-backColor {
  background-color: transparent !important;
  color: #4f6c7c !important;
}

.form-control:hover {
  border-color: #00a5e3;
}

.form-control:focus {
  border-color: #00a5e3 !important;
  box-shadow: inset 0 1px 1px rgba(241, 38, 38, 0.075),
    0 0 8px rgba(0, 162, 255, 0.473) !important;
}

.card-buttons {
  display: flex;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: attr(data-content);
  color: #fff;
  z-index: 0;
}

.custom-file-label::after {
  color: #4f6c7c;
  background-color: #4f6c7c;
  z-index: 0;
}

.custom-file-label {
  z-index: 0;
}

.custom-file-input {
  z-index: 0;
}

/* TABLES */
.table td,
.table th {
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
  color: #6e84a3;
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}

.table thead {
  border-bottom: transparent;
}

.table th {
  background-color: transparent;
}

.table-sm thead th {
  font-size: 0.625rem;
}

.table-sm td,
.table-sm th {
  padding: 0.75rem !important;
}

.table-round-corner {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  border-collapse: separate;
  border: solid #ccc 1px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.table tbody {
  border-radius: 15px;
}

.footer-table th {
  line-height: normal;
  font-size: 15px;
  font-weight: bold;
  color: #edf2f9;
  padding-left: 3rem;
  vertical-align: top;
  padding-bottom: 0.3rem;
}

.footer-table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.76);
}

.footer-table td {
  line-height: normal;
  text-align: right;
  font-size: 12px;
  color: #edf2f9;
  padding-left: 3rem;
  vertical-align: bottom;
  padding-top: 0.3rem;
}

.datatable-custom {
  background-color: white;
}

.datatable-custom.table .thead-light tr,
.datatable-custom.table .thead-light tr:hover {
  background-color: #dee2e6 !important;
}

.datatable-custom.table .thead-light th {
  background-color: transparent !important;
}

.datatable-custom tr:hover {
  background-color: #e2e6ed !important;
}

td {
  vertical-align: middle !important;
  color: #6e84a3;
}

.datatable-custom td.td-total {
  background-color: #95aac9;
  color: white;
  font-weight: bold;
}

.tb-small td {
  padding: 0.5rem !important;
}

.datatable-custom .btn-outline-secondary {
  color: #6e84a3;
  border: 2px solid #6e84a3 !important;
}

.datatable-footer {
  color: #95aac9;
  border-top: 1px solid #edf2f9;
  padding: 0.75rem 0;
}

.datatable-footer span,
.datatable-footer select {
  font-size: 0.75rem;
}

.datatable-footer select {
  color: #95aac9;
  border-color: #95aac9;
  cursor: pointer;
}

.datatable-footer select:hover {
  color: #183153;
}

.datatable-buttons button {
  background-color: transparent;
  border: none;
  color: #6e84a3;
  padding: 0 0.35rem;
}

.datatable-buttons button:hover {
  color: #0050c3;
}

.card-table.table {
  margin-bottom: 0px;
}

.card-table.table thead {
  border-top: 0px solid #edf2f9;
  background-color: #f9fbfd !important;
  border-bottom: 0px;
}

.card-table tbody td:first-child,
.card-table thead th:first-child {
  padding-left: 1.5rem !important;
}

.card-table tbody td:last-child,
.card-table thead th:last-child {
  padding-right: 1.5rem !important;
}

.card-table .thead-light th {
  padding: 1rem !important;
  background-color: transparent;
  font-size: 0.625rem;
  letter-spacing: 0.15em;
  color: #95aac9 !important;
}

.card-table tr:hover {
  background: #f9fbfd;
}

.card-table-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1370px) {
  .footer-table th {
    padding-left: 2rem;
  }

  .footer-table td {
    padding-left: 2rem;
  }

  .mobile-max {
    max-width: 1100px !important;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tabela {
    overflow-x: scroll !important;
  }

  .sector {
    overflow-x: scroll !important;
  }

  .hiddenX {
    overflow-y: scroll;
    overflow-x: hidden !important;
  }
}

/* SWEETALERT CUSTOM */
.swal2-title {
  color: #12263f;
  font-size: 1.75rem;
}

.swal2-content {
  color: #506d7c;
  font-size: 1rem;
}

.swal2-actions button {
  font-size: 1rem !important;
  font-weight: normal;
}

.swal2-container.swal2-center {
  z-index: 10000;
  align-items: center;
}

.swal2-icon.swal2-warning {
  border-color: #f6c343;
  color: #f6c343;
}

.page-title {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 1;
  color: #171719;
  margin-top: -40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Lato", serif;
}

.header-full .page-title {
  margin-top: 0px;
}

.page-header-infos {
  align-items: center;
}

.page-title h2 {
  text-align: left;
  font-family: "Lato", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0;
  color: #171719;
  opacity: 1;
}

.page-title p {
  font-family: "Lato", serif;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0;
  color: #525059;
  opacity: 1;
  margin: 0px;
}

.page-title .box-btn {
  text-align: right;
}

.page-title .btn {
  width: 100% !important;
  font-size: 15px;
  width: auto;
  margin-top: 0.25rem;
}

.page-title .btn-primary.c3.btn-dashed {
  border-style: dashed !important;
}

.border-primary-subtitle {
  text-align: center;
  font: Light 11px/13px Roboto;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  letter-spacing: 0;
  color: #3f25a8;
  opacity: 1;
}

.border-primary {
  border: 2px solid #3f25a8 !important;
  border-radius: 8px;
  opacity: 1;
  padding: 6px;
  text-align: center;
  color: #3f25a8;
}

.card-search {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
}

.card-search span {
  padding: 10px 0;
}

.card-search .icon-search {
  margin: 0 10px;
  width: 1rem;
  height: auto;
  color: #4f6c7c;
}

.input-custom {
  color: #6e84a3 !important;
  display: flex;
  margin-right: 3px;
  width: 95%;
  height: 48px;
  padding: 0 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: transparent;
  align-items: center;
}

.input-custom:focus {
  border: transparent !important;
  outline: none;
}

.input-custom:active {
  border: transparent;
}

.input-custom-input {
  background: white;
  width: calc(100% - 40px);
}

.drop-custom {
  width: 100%;
  position: relative;
}

.drop-custom.show {
  border-radius: 8px 8px 0 0;
}

.drop-custom .input-custom {
  color: #95aac9;
}

.drop-custom .dropdown-menu {
  position: absolute;
  width: calc(100% - 30px);
  margin: 0 15px;
}

.drop-custom.show .dropdown-menu {
  width: 100% !important;
  display: block;
  border: 1px solid #ececec;
  border-radius: 0.5rem;
  padding: 0;
  margin: 0.5rem 0 !important;
}

.drop-custom .dropdown-menu .dropdown-item {
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e84a3;
  border-bottom: 1px solid #e9ecef;
}

.drop-custom .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0;
}

.dropdown-a {
  cursor: pointer;
}

.dropdown-a:hover {
  color: #ddd;
}

.dropbtn {
  cursor: pointer;
  font-size: 16px;
  border: none;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-buttons .dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #6e84a3;
  display: block;
  font-weight: 400;
  padding: 0.375rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.dropdown-buttons .dropdown-item:focus,
.dropdown-item:hover {
  color: #12263f;
  text-decoration: none;
  background-color: #e9ecef !important;
}

.dropdown-buttons svg {
  margin-right: 1rem;
  width: 1rem;
  max-height: 0.9rem;
}

#dropModal {
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;
}

.event-location {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  color: #95aac9;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}

.event-p-title {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0;
  color: #4f6c7c;
  opacity: 1;
  margin: 7px 0px;
  font-weight: 500;
}

.event-span {
  font-weight: 300;
}

.icon-button {
  display: inline-block;
  font-size: inherit;
  height: 1.5em;
  overflow: visible;
  vertical-align: -0.125em;
}

.eZPZaV,
.doqUdF,
.kjhGqe,
.favqoi,
.heAbaq,
.gibaWW {
  background: transparent;
}

.eaCdSY {
  border-radius: 8px;
}

.jzBROA {
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.favqoi {
  border-radius: 8px;
}

.subRow {
  margin-top: -1px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0px;
}

.inLnHE {
  margin-top: 10px;
  border-radius: 8px 8px 0 0;
}

.heAbaq {
  color: #495057;
}

/* CORRIGIR CLASSES */
.jwPGvH {
  margin-top: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-left: 10px solid #4f6c7c;
  border-radius: 8px;
  transition: all 0.3s;
}

.jwPGvH:hover {
  border-left-color: #8f44dc;
}

.jwPGvH-color {
  margin-top: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-left: 10px solid #8f44dc;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  color: #4f6c7c;
  background-color: #ffffff;
  min-height: 48px;
}

.jwPGvH-color:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
}

.gibaWW {
  color: #7d878a;
}

.qCZjB {
  border-radius: 8px;
}

.qCZjB:focus:not(:disabled) {
  background-color: transparent;
}

.text-small,
.text-small span {
  font-size: 0.75rem !important;
}

.select-search {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search__value {
  position: relative;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 7px);
  right: 19px;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  border-right: 1px solid #6e84a3;
  border-bottom: 1px solid #6e84a3;
  pointer-events: none;
}

.select-search__input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search__input[value=""] {
  color: greenyellow !important;
}

.select-search__input:hover {
  border-color: #00a5e3;
}

.select-search__input:focus {
  border-color: #00a5e3 !important;
  box-shadow: inset 0 1px 1px rgb(241 38 38 / 8%), 0 0 8px rgb(0 162 255 / 47%) !important;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  height: 180px;
  overflow: auto;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__options {
  list-style: none;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  color: #5b7190;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.9rem;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  line-height: 1rem;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  border-color: #5b7190;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #5b7190;
  color: white;
}

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

.custom-control-label {
  cursor: pointer;
}

.custom-control-label::before {
  background-color: #f3f6f9;
  border: 1px solid #c9cdd0;
}

.custom-switch .custom-control-label::after {
  background-color: #fff;
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #8f44dc;
  background-color: #a26fd6;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.footer-equip {
  position: sticky;
  bottom: 0px;
  height: 60px;
  width: 100%;
  background-color: #4f6c7c;
  padding-top: 10px;
  overflow: hidden;
}

.footer-equip .row {
  margin-left: 0;
  margin-right: 0;
}

.footer-equip .select-search__select {
  top: auto !important;
  bottom: 60px !important;
}

@media screen and (max-width: 1920px) {
  .lot-value {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1440px) {
  .lot-value {
    font-size: 15px !important;
  }
}

.border-bottom {
  border-bottom: 1px solid #4f6c7c !important;
  opacity: 1;
}

/* BASCIS */
.box-button {
  text-align: right;
  padding-bottom: 40px;
}

.box-button button {
  border-radius: 8px;
}

/* FORMS */
.card-img-upload {
  border: 2px dashed #edf2f9;
}

.upload-desc {
  margin-top: 5px;
  display: block;
  color: #95aac9;
}

.card-img-upload .input-group-text svg {
  margin-right: 10px;
}

.card-img-upload label {
  overflow: hidden;
  padding: 10px 75px 0px 10px;
  font-size: 12px;
  height: 38px;
  white-space: nowrap;
}

.card-img-upload label::after {
  font-size: 14px;
  height: 36px;
}

.input-radius-left {
  border-radius: 0.375rem 0 0 0.375rem;
}

.input-radius-right {
  border-radius: 0 0.375rem 0.375rem 0;
}

.img-uploaded {
  border-radius: 0.375rem !important;
  width: 64px;
  height: 64px;
  background-size: cover;
  float: right;
}

.banner-uploaded {
  border-radius: 0.375rem !important;
  width: 128px;
  height: 64px;
  background-size: cover;
  float: right;
}

.input-group-text {
  background-color: #f3f6f9;
  font-size: 1rem;
}

/* COMPONENTS */
.cards,
.card-default {
  border-radius: 8px;
  opacity: 1;
  padding: 3px;
  min-height: 85px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.card-default {
  background-color: #6e84a3;
}

.card-span {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.card-subspan {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  color: #e9f2f2;
  opacity: 1;
  margin-top: -20px;
}

.card-row {
  position: relative;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* transition: 0.3s; */
}

.card-row:hover {
  background: #edf2f9;
}

.card-row-main {
  margin-top: 0.25rem;
}

.card-row-main,
.card-row .card-row-infos {
  display: flex;
  align-items: center;
}

.card-row-name {
  margin-left: 1rem;
}

.btn-table {
  cursor: pointer;
  margin-left: 0.5rem;
  border: none;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 25px;
  font-size: 0.7rem;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 12px;
  border-radius: 50px;
  background-color: #95aac9 !important;
  color: #fff !important;
  box-shadow: 0px 3px 6px #2a54663f;
}

@media screen and (max-width: 768px) {
  .pagetype-tickets {
    margin-bottom: 80px;
  }
}

.btn-table:hover {
  background-color: #183153 !important;
}

.icon-table {
  background: transparent;
  cursor: pointer;
  margin: 0 0.5rem;
  border: none;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 25px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 12px;
}

.icon-table svg {
  color: #2c7be5;
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.icon-table:hover svg {
  color: #95aac9;
}

.mobile-buttons .btn-table span {
  display: none;
}

.mobile-buttons .btn-table:last-child {
  border-bottom: 0;
}

.card-row .mobile-buttons {
  position: absolute;
  align-items: center;
  display: flex;
  border-radius: 6px;
  justify-content: right;
  position: absolute;
  right: 1rem;
  width: fit-content;
  z-index: 1000;
  padding: 0.5rem 0;
}

.mobile-buttons .toogle-button {
  justify-content: space-between;
}

.event-online svg {
  color: #00d97e;
}

.event-offline svg {
  color: #e63757;
}

@media screen and (max-width: 415px) {
  .card-row-name {
    max-width: 310px;
    float: left;
  }
}

@media screen and (max-width: 375px) {
  .card-row-name {
    max-width: 280px !important;
  }
}

@media screen and (max-width: 360px) {
  .card-row-name {
    max-width: 250px !important;
  }
}

@media screen and (max-width: 320px) {
  .card-row-name {
    max-width: 220px !important;
  }
}

@media screen and (max-width: 768px) {
  /* .card-search {
    margin-top: 10px;
  } */
  .form-control {
    margin-bottom: 5px;
  }

  .page-title {
    height: auto !important;
  }

  /* .page-header-infos{
    justify-content: center;
    flex-direction: column;
  } */
  .page-title h2 {
    text-align: center !important;
    padding-left: 0px !important;
    font-size: 1rem;
  }

  .page-title p {
    text-align: center !important;
    padding-left: 0px !important;
    display: none;
  }

  .page-title .box-btn {
    text-align: center;
  }

  .page-title .btn {
    padding: 0.375rem 1.5rem;
    font-size: 0.8rem;
    width: auto;
  }

  .card-recipes {
    padding-left: 10px;
    padding-top: 5px;
  }

  .mt-mobile {
    margin-top: 20px;
  }

  .hidecallendar {
    display: none;
  }

  .content {
    padding: 10px;
  }

  .nav-link {
    display: block;
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    font-weight: normal;
  }

  .event-name {
    font-size: 18px !important;
    text-align: center;
  }

  .hidden-mobile {
    display: none;
  }
}

.icon-circle {
  margin-right: 0.8rem;
  float: left;
  font-size: 0.8rem;
  border-radius: 50px;
  border: 2px solid white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle-sm {
  margin-right: 0.6rem;
  float: left;
  font-size: 0.6rem;
  border-radius: 50px;
  border: 2px solid white;
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tips,
.tips-component {
  margin-left: 0.25rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.tips svg,
.tips-component svg {
  color: #95aac9;
}

.page-title .tips svg,
.page-title .tips-component svg {
  color: white;
}

.tips .tips-content,
.tips-component .tips-content,
.tips-component .tip-trust-level {
  visibility: hidden;
  width: 240px;
  background-color: #12263f;
  color: #95aac9;
  text-align: left;
  border-radius: 6px;
  padding: 0.8rem 0.95rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -120px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.75rem;
  font-weight: normal;
  white-space: normal;
}

.tips .tips-content a,
.tips-component .tips-content a,
.tips-component .tip-trust-level a {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.tips .tips-header,
.tips-component .tips-header {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
}

.chevron-padding {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAYAAAA+stv/AAAAAXNSR0IArs4c6QAAAARjSUNQAQ0AAZxpOzIAAACWSURBVFiF7dIxCsMwDAXQry4mm7Y4m3uDXiEn9xV6g3qrcwplkSGEDHEolMB/oyykj22AiIjoz6SneRynDwCI2FxrLduzGGMykwwAy/J9np356AngkplkVdVWUFX15al3WFcAEZsBFAAphCGrC2Foy4v3nJ/Zm3hz1QnA28uvtnz/ND8PcBACV5dfDrALcfgpiYiI6DZWwCAz9Q3F6KIAAAAASUVORK5CYII=);
  background-position: 100%;
  background-repeat: no-repeat;
  padding: 0.5rem;
}

.tips .tips-content::after,
.tips-component .tips-content::after,
.tips-component .tip-trust-level::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #12263f transparent transparent transparent;
}

.tips:hover .tips-content,
.tips-component:hover .tips-content,
.tips-component:hover .tip-trust-level {
  visibility: visible;
  opacity: 1;
  z-index: 100000;
}

.tooltip-custom {
  color: #fff !important;
  background-color: #183153 !important;
  z-index: 10000 !important;
}

.tooltip-custom.place-top::after,
.tooltip-custom.place-bottom::after {
  border-color: #183153 transparent !important;
}

.tooltip-custom.place-right::after,
.yourClass.place-left::after {
  border-color: transparent #183153 !important;
}

.jodit-workplace p {
  font-weight: unset !important;
  font-size: 1rem !important;
}

.jodit-workplace p a {
  cursor: pointer !important;
  color: #0050c3 !important;
}

.jodit,
.jodit *,
.jodit-container,
.jodit-container * {
  font-size: 1rem !important;
}

.icon-rotate {
  transform: rotate(-45deg);
}

/* MODAIS */
.side-modal {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 10000;
  background-color: white !important;
  transition: 0.5s;
}

.side-modal2 {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  background-color: white !important;
  transition: 0.5s;
}

@media only screen and (max-width: 968px) {
  .side-modal2 {
    width: 100%;
  }
}

.middle-modal {
  width: 50%;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10000;
  background-color: white !important;
  transition: 0.5s;
}

.background-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 10000;
  background-color: rgba(0 0 0, 0.5);
  transition: 0.5s;
}

.modal-hidden {
  right: -50% !important;
}

.modal-show {
  right: 0% !important;
}

.left-modal-hidden {
  left: -50% !important;
}

.left-modal-show {
  left: 0% !important;
}

.left-side-modal {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 50000;
  background-color: white;
  transition: 0.5s;
}

.left-side-modal .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-side-modal .card-header h4 {
  font-weight: bold;
}

.side-modal-visible {
  display: initial;
}

.side-modal.side-modal-hidden,
.side-modal.hide {
  right: -50%;
}

.side-modal .card-body {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.side-modal .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-modal .card-header h4 {
  font-weight: bold;
}

.side-modal .modal-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.side-modal .modal-footer2 {
  position: relative;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.side-modal .flpzgB {
  display: none !important;
}

@media screen and (max-width: 992px) {
  .side-modal {
    width: 100%;
    height: 100%;
    right: 0;
    border: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .side-modal.side-modal-hidden {
    right: -100% !important;
  }

  .modal-hidden {
    right: -100% !important;
  }
}

/* AJUSTES GERAIS */
.__react_component_tooltip {
  opacity: 0 !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* AJUSTES GRID */
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

/* AJUSTES TABELAS */
#dataTable-body .dataTable-td:first-child {
  padding-left: 10px;
}

.dataTable-thead .dataTable-th:first-child {
  padding-left: 15px;
}

.dataTable-th,
.rdt_TableHeadRow .rdt_TableCol_Sortable {
  color: #95aac9;
}

.card .tips {
  margin-left: 0.5rem;
}

.card .rdt_TableHead .rdt_TableCol:last-child,
.card .rdt_TableRow .rdt_TableCell:last-child {
  justify-content: flex-end;
}

.card .rdt_TableRow .rdt_TableCell:last-child div {
  padding-right: 15px;
}

.card .rdt_TableHead {
  font-size: 0.625rem !important;
  background-color: #f9fbfd !important;
}

.card .rdt_TableHead .rdt_TableHeadRow {
  border-radius: 0px;
  border: 0px;
  min-height: 48px;
}

.card .rdt_TableHead .rdt_TableCol .rdt_TableCol_Sortable {
  color: #95aac9 !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
}

.card .rdt_TableHead .rdt_TableCol:last-child .rdt_TableCol_Sortable {
  justify-content: flex-end;
}

.card .rdt_TableBody .rdt_TableRow {
  border-top: 1px solid #edf2f9 !important;
  border-bottom: 0px !important;
  border-left: 0px;
  margin-top: 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.card .rdt_TableBody .rdt_TableRow:hover {
  background-color: #f9fbfd !important;
}

.card .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  border-left: 10px solid #edf2f9 !important;
}

.card .rdt_TableBody .rdt_TableRow.row-active .rdt_TableCell:first-child {
  border-left-color: #8f44dc !important;
}

.card .rdt_TableRow .rdt_TableCell:last-child span {
  padding-right: 15px;
  justify-content: flex-end;
}

.card .rdt_TableRow .rdt_TableCell {
  color: #12263f;
}

.rdt_TableRow .rdt_TableCell:last-child,
.rdt_TableHeadRow .rdt_TableCol:last-child .rdt_TableCol_Sortable {
  justify-content: flex-end;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.table-sm {
  font-size: 0.8125rem;
}

.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #12263f;
}

.table thead {
  border-top: 1px solid #edf2f9;
  border-bottom: 1px solid #edf2f9;
  background-color: #f9fbfd !important;
  color: #95aac9 !important;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.08em;
}

.table thead th {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  color: #95aac9;
  border: none;
}

.table.table-bordered thead th {
  border: 1px solid #dee2e6;
}

.table .thead-light {
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
}

.table .thead-light th {
  color: #6e84a3 !important;
  border: none !important;
}

.table .thead-light tr:hover {
  color: #e2e6ed !important;
}

.table-sm td,
.table-sm th {
  padding: 1rem;
}

/* CUSTOM */
.alert {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.alert-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.alert-secondary {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3;
}

.alert-success {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e;
}

.alert-warning {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343;
}

.alert-danger {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757;
}

.alert-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}

.alert-light {
  color: #283e59;
  background-color: #edf2f9;
  border-color: #edf2f9;
}

.alert-dark {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f;
}

.badge {
  display: inline-block;
  padding: 0.5em 1.4em;
  font-size: 76%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pill {
  padding-right: 0.8em !important;
  padding-left: 0.8em !important;
  border-radius: 10rem !important;
}

.badge.rounded-pill {
  padding: 0.33em 0.6em !important;
}

.badge.bg-danger {
  color: #fff !important;
  background-color: #e63757 !important;
}

.badge.bg-success {
  color: #fff !important;
  background-color: #00d97e !important;
}

.badge.bg-primary {
  color: #fff !important;
  background-color: #2c7be5 !important;
}

.badge.bg-info {
  color: #fff !important;
  background-color: #39afd1 !important;
}

.badge.bg-primary-soft {
  color: #2c7be5 !important;
  background-color: #d5e5fa !important;
}

.badge.bg-secondary-soft {
  color: #6e84a3 !important;
  background-color: #e2e6ed !important;
}

.badge.bg-success-soft {
  color: #00d97e;
  background-color: #ccf7e5;
}

.badge.bg-info-soft {
  color: #39afd1 !important;
  background-color: #d7eff6 !important;
}

.badge.bg-warning-soft {
  color: #f6c343 !important;
  background-color: #fdf3d9 !important;
}

.badge.bg-danger-soft {
  color: #e63757 !important;
  background-color: #fad7dd !important;
}

.badge.bg-light-soft {
  color: #edf2f9 !important;
  background-color: #fbfcfe !important;
}

.badge.bg-dark-soft {
  color: #12263f !important;
  background-color: #d0d4d9 !important;
}

.btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.375rem !important;
}

.btn-success {
  background-color: #00d97e;
  border-color: #00d97e;
}

.btn-success:hover {
  background-color: #00b86b;
  border-color: #00ae65;
}

.btn-danger {
  background-color: #e63757;
  border-color: #e63757;
}

.btn-danger:hover {
  background-color: #c42f4a;
  border-color: #b82c46;
}

.btn-dark {
  background-color: #12263f !important;
  border-color: #12263f !important;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  background-color: #0f2036 !important;
  border-color: #0e1e32 !important;
}

.btn-white {
  color: #283e59;
  background-color: #fff;
  border-color: #e3ebf6;
}

.btn-white.active {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}

.btn-outline-success {
  border-color: #00d97e;
  color: #00d97e;
}

.btn-outline-success:hover {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}

.btn-outline-secondary {
  color: #ced4da;
  background-color: transparent;
  background-image: none;
  border: 2px solid #ced4da !important;
  width: 100%;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
}

.btn-outline-gray {
  color: #6e84a3;
  background-color: transparent;
  background-image: none;
  border: 2px solid #6e84a3 !important;
  width: 100%;
  font-weight: bold;
}

.btn-outline-gray:hover,
.btn-outline-gray:active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.btn-outline-gray:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
}

.btn-primary {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}

.btn-primary:hover {
  background-color: #2569c3;
  border-color: #2362b7;
}

.btn-primary:disabled {
  background: #600ea3;
  border: 1px solid #600ea3;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
  width: 100%;
}

.btn-secondary:hover {
  background-color: #5b7190 !important;
  border-color: #566b88 !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.text-red {
  color: #e63757 !important;
}

.text-green {
  color: #00d97e !important;
}

.event-state td:first-child {
  border-left-width: 10px;
  border-left-style: solid;
}

.event-primary td:first-child {
  border-left-color: #2c7be5;
}

.event-secondary td:first-child {
  border-left-color: #6e84a3;
}

.event-success td:first-child {
  border-left-color: #00d97e;
}

.event-warning td:first-child {
  border-left-color: #f6c343;
}

.event-danger td:first-child {
  border-left-color: #e63757;
}

.event-info td:first-child {
  border-left-color: #39afd1;
}

.event-light td:first-child {
  border-left-color: #edf2f9;
}

.event-dark td:first-child {
  border-left-color: #12263f;
}

.close {
  float: right;
  font-size: 1.40625rem;
  font-weight: 600;
  line-height: 1;
  color: #95aac9;
  text-shadow: none;
  opacity: 0.5;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
  color: #95aac9;
  border: 0;
}

button:focus {
  outline: none !important;
}

/* RULES */
.dev-feature,
.dev-feature.btn {
  border: 2px solid red !important;
  display: none;
}

.blursystem {
  filter: blur(3px);
}

.span-default {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  color: #7c7c7c;
  font-weight: bold;
  transition: 0.5s all;
  opacity: 1;
}

.span-active {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  font-weight: bold;
  color: #00a5e3;
  transition: 0.5s all;
  opacity: 1;
}

.span-white {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  font-weight: bold;
  color: #fff;
  transition: 0.5s all;
  opacity: 1;
}

.span-white-number {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  color: #fff;
  transition: 0.5s all;
  opacity: 1;
}

.footer-page {
  bottom: 0px;
  height: 60px;
  width: 100%;
  background-color: #4f6c7c;
  padding-top: 10px;
  position: fixed;
}

.footer-page .row {
  margin-left: 0;
  margin-right: 0;
}

#select-stock .select-search {
  margin-bottom: 0px !important;
}

body::-webkit-scrollbar {
  height: 5px;
  overflow: overlay;
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 20px;
  transition: all 0.5s;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #dc44bb;
  border-radius: 20px;
  border: 3px solid #dc44bb;
}

.tabela::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.tabela::-webkit-scrollbar-track {
  background: #f9fbfd;
}

.tabela::-webkit-scrollbar-thumb {
  background-color: #8f44dc;
  border-radius: 10px;
  border: 3px solid #8f44dc;
}

.tabela::-webkit-scrollbar-button:single-button {
  background-color: #f9fbfd;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}

.sector::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sector::-webkit-scrollbar-track {
  background: transparent;
}

.sector::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 10px;
}

.tabela::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 5px 14px 5px 0;
  border-color: transparent #4f6c7c transparent transparent;
}

.tabela::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent #8f44dc transparent transparent;
}

.tabela::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 5px 0 5px 14px;
  border-color: transparent transparent transparent #4f6c7c;
}

.tabela::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: transparent transparent transparent #8f44dc;
}

.side-modal::-webkit-scrollbar {
  width: 10px;
}

.side-modal::-webkit-scrollbar-track {
  background: #fff;
}

.side-modal::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 20px;
  border: 3px solid #600ea3;
  transition: all 0.5s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-label {
  font-weight: bold;
  color: #4f6c7c;
  font-size: 12px;
  margin-bottom: -2px;
  margin-top: 10px;
}
.remove {
  color: transparent;
}

.category-counter {
  font-weight: bold;
  color: #95aac9;
  font-size: 13px;
  margin-bottom: -2px;
  margin-top: 10px;
}

.side-modal label {
  font-weight: bold;
  color: #4f6c7c;
  font-size: 12px;
  margin-bottom: -2px;
  margin-top: 10px;
  margin-bottom: 0.5rem;
}

.side-modal .form-check-label {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}

.side-modal .special-control {
  background-color: #e5eff6 !important;
  border-color: #e5eff6 !important;
  font-weight: bold !important;
  color: #506d7c !important;
}

.side-modal .special-control option {
  font-weight: bold !important;
  color: #506d7c !important;
}

.filter-button {
  color: rgb(68, 86, 154);
  border: 2px solid rgb(68, 86, 154);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

#filter-button-sales {
  margin-top: 2px;
  padding-top: 7px;
  padding-bottom: 6px;
}

#cleanFilters {
  margin-top: 2px;
  padding-top: 7px;
  padding-bottom: 6px;
  color: rgba(231, 55, 55, 0.973);
  border-color: rgba(231, 55, 55, 0.973);
}

#cleanFilters:hover {
  background-color: rgb(243, 209, 209);
}

.filter-button:hover {
  border: 2px solid rgba(68, 86, 154, 0.8);
  background-color: rgba(68, 86, 154, 0.3);
}

.side-modal .custom-file-label {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-size: 16px !important;
}

.card .favqoi {
  border-left: 10px solid #edf2f9 !important;
}

.custom-input-option,
.custom-input-checkbox,
.custom-input-checkbox-disabled {
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  color: #fff;
  background-color: #6e84a3;
  transition: all 0.5s;
}

.recycle-bin {
  width: 24px;
  height: 24px;
}

.trash-icon:hover img {
  filter: brightness(10);
}

.custom-input-option:hover,
.custom-input-checkbox:hover {
  background-color: #12263f;
}

.swal2-container, .swal2-center, .swal2-backdrop-show {
  z-index: 2000000 !important;
}

.custom-input-option.active,
.custom-input-checkbox.active,
.custom-input-checkbox-disabled.active {
  background-color: #2075f4;
}

.custom-input-option.active:hover {
  background-color: #125fd3;
}

.custom-input-checkbox,
.custom-input-checkbox-disabled {
  font-size: 0.75rem;
}

.custom-input-checkbox.active:hover {
  background-color: #1366e2;
}

.ReactModal__Content {
  transform: translate(100%, 0);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translate(0, 0);
  background-color: transparent;
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--before-close {
  transform: translate(100%, 0);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: transparent;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.fadeIn {
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.infiteSpining {
  animation: infinite-spinning 1s infinite;
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.red-button {
  cursor: pointer;
  font-size: 28px;
  color: #e30f00;
}

.red-button:hover {
  color: #e30f00af;
}

.red-button.disabled {
  color: #707070;
  cursor: default !important;
}

@media (max-width: 768px) {
  .category-counter {
    display: none;
  }
}
