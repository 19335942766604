/* COLORS */
div.c1,
button.c1,
nav.c1,
.btn.c1,
span.c1 {
  background-color: #00a5e3 !important;
  color: white;
}

.btn.c1:hover {
  background-color: #0061aa !important;
}

div.c2,
button.c2,
nav.c2,
.btn.c2,
span.c2 {
  background-color: #8f44dc !important;
  color: white;
}

div.b2 {
  border-color: #8f44dc !important;
}

.btn.c2:hover {
  background-color: #7228bf !important;
}

div.c3,
button.c3,
nav.c3,
.btn.c3,
span.c3 {
  background-color: #600ea3 !important;
  color: white;
}

.btn.c3:hover {
  background-color: #261d54 !important;
}

.page-title.c2 {
  background: #183153 !important;
}

.page-title .btn-primary.c3 {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 50px !important;
}

.page-title .btn-primary.c3:hover {
  background: #4f6c7c !important;
  border-color: #4f6c7c !important;
  color: white !important;
}

.outline.btn.btn-primary.c3 {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 50px;
}

.outline.btn.btn-primary.c3:hover {
  background: #95aac9 !important;
  border-color: #95aac9 !important;
  color: white !important;
}

button.btn-outline-primary {
  background-color: transparent !important;
  border: 1px solid;
  color: #2c7be5;
  border-color: #2c7be5;
}

button.btn-outline-primary:hover {
  color: #fff;
  background-color: #2c7be5 !important;
  border-color: #2c7be5 !important;
}

/* GENERAL */
#sidebar .nav-active {
  background: transparent linear-gradient(270deg, #0050c3 0%, #0050c3 100%) 0% 0% no-repeat padding-box;
  border-left: solid 2px #19f8fc;
}

.sidebarIcon {
  color: #00a7e5;
}

.card-one {
  background: #3f25a8 0% 0% no-repeat padding-box;
}

.card-grey {
  background: #61789a 0% 0% no-repeat padding-box;
}

.card-dark-purple {
  background: #4b1db5 0% 0% no-repeat padding-box;
}

.card-two {
  background: #7244dc 0% 0% no-repeat padding-box;
}

.card-three {
  background: #2075f4 0% 0% no-repeat padding-box;
}

.card-four {
  background: #00a7e1 0% 0% no-repeat padding-box;
}

.card-five {
  background: #d9277a 0% 0% no-repeat padding-box;
}

/* LOGIN */
#page-login,
#page-login .row {
  height: 100% !important;
}

.bg-cover {
  background: #600ea3;
}

/*LOADING*/
#loading-border {
  border-color: #20c4f4;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
    color: #600ea3;
  }

  50% {
    transform: rotate(180deg);
    color: #20c4f4;
  }

  100% {
    transform: rotate(359deg);
    color: #600ea3;
  }
}

#loading-icon span {
  background-color: #20c4f4;
}

#loading p:nth-child(2) {
  color: #20c4f4;
}