.subRow {
  border: 2px solid #3f25a8;
}
.margin0 {
  margin: 0 !important;
}
.qCZjB:hover:not(:disabled) {
  background: none;
}
.iuCFTz {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.card .gAuPhq {
  border-radius: 0px !important;
}
@media screen and (min-width: 924px) {
  .gAuPhq {
    overflow-x: hidden !important;
  }
}
.comprador-detail p {
  color: #4f6c7c;
  font-size: 1em;
}
.participantes-header {
  background-color: #f9fbfd !important;
  padding: 10px 0;
  color: #95aac9 !important;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.8rem;
}
.participante-detail {
  margin-top: 5px;
}
.payment-icon {
  width: 25px !important;
  margin-right: 0.5rem;
  filter: invert(60%) sepia(26%) saturate(208%) hue-rotate(158deg)
    brightness(101%) contrast(94%);
}
button.btn-red-border {
  background-color: transparent !important;
  border: 2px solid #e63757 !important;
  color: #183153;
}
.btn-red-border svg {
  color: #e63757;
}
button.btn-red-border:hover {
  color: #e63757;
}
.sale-btn {
  display: flex;
  justify-content: space-around;
}
#table-totalsales thead th {
  cursor: pointer;
}
#table-totalsales thead th {
  opacity: 0.5;
}
#table-totalsales thead th svg {
  margin-left: 0.25rem;
}
#table-totalsales thead th.rotated svg {
  transform: rotate(180deg);
}
#table-totalsales thead th.arrow-active {
  opacity: 1;
}
#table-totalsales tr {
  cursor: pointer;
}
#table-totalsales .badge {
  min-width: 75px;
}
#table-totalsales .payment-type {
  color: #95aac9;
}
#table-totalsales .payment-type svg {
  color: #6e84a3;
}

.input-form-container {
  width: 100%;
}

@media (max-width: 768px) {
  .sale-btn {
    margin-left: 15px;
    width: 30%;
  }

  ReactModal__Content ReactModal__Content--after-open {
    transform: translate(-50%, -60%) !important;
  }
}
